@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Montserrat" !important;
}

@import "assets/style/app";


// :where(.css-1wkuv9c).ant-menu .ant-menu-item .ant-menu-item-icon, :where(.css-1wkuv9c).ant-menu .ant-menu-submenu-title .ant-menu-item-icon, :where(.css-1wkuv9c).ant-menu-light .ant-menu-item a, :where(.css-1wkuv9c).ant-menu-light>.ant-menu .ant-menu-item a, :where(.css-1wkuv9c).ant-menu-light .ant-menu-item a:hover, :where(.css-1wkuv9c).ant-menu-light>.ant-menu .ant-menu-item a:hover {
//   color: white !important;
// }


:where(.css-dev-only-do-not-override-qi43ln).ant-select .ant-select-arrow {
  color: #50B848 !important;
  font-size: 17px !important;
}

h4.ant-typography.css-dev-only-do-not-override-qi43ln {
  color: #fff !important;
  font-weight: 100;
}

// :where(.css-qi43ln).ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
// .ant-select.ant-select-in-form-item.css-qi43ln.ant-select-single.ant-select-show-arrow {
//   height: 50px !important;
// }

:where(.css-qi43ln).ant-select .ant-select-arrow {
  color: #50B848 !important;
  font-size: 14px !important;
  margin-left: 5px !important;
}


span.anticon.anticon-down.ant-select-suffix,
:where(.css-qi43ln).ant-select .ant-select-arrow>* {
  color: #6cb557 !important;
}

.ant-empty-description {
  color: white !important;
}

.ant-select-dropdown.css-qi43ln.ant-select-dropdown-placement-bottomLeft {
  border: 2px solid rgb(40, 53, 73) !important;
  background: rgb(12, 17, 26) !important;
}

.ant-select-item.ant-select-item-option.ant-select-item-option-active.ant-select-item-option-selected {
  background: #283549 !important;
}

span.ant-select-selection-item {
  color: hsla(0, 0%, 100%, .7) !important;
}

.ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  background: none !important;
}


// Colours
.green-color {
  color: $primary-color;
}

.yellow-color {
  color: $yellow-color;
}

.red-color {
  color: $red-color;
}

.green-background-color {
  background-color: $primary-color;
}

.purple-color {
  color: $secondary-color !important;
}

.purple-background-color {
  background-color: $secondary-color;
}

.notInvited {
  color: #ff4f4f !important;
}

.invitationSent {
  color: #ecb738 !important;
}

.completed {
  color: #50b848 !important;
}

.genrate_invoice_btn {
  padding: 11px 10px;
  background: #283549;
  border: 1px solid;
  color: white;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
  font-family: "Montserrat" !important;
  text-transform: capitalize;
}